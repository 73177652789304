

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { setterDate } from '@/plugins/utils';
import { RequestModel } from '@/models/Request.model';
@Component
export default class TableRowRequest extends Vue {
  @Prop({ required: true, type: RequestModel })
  request!: RequestModel;

  @Prop({ default: false, type: Boolean })
  showEmail!: boolean;

  setterDate = setterDate;

  request_data = {
    referencia_express: false,
  };

  mounted() {
    this.request_data = JSON.parse(this.request.request_data);
  }

  get isFast4Hours() {
    return this.request_data.referencia_express;
  }
}
